<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Subscriber Group
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      isFc: false,
      isSLD: false,
    }
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        {
          icon: 'mdi-file-tree',
          path: '/subscriber_tier',
          title: 'Tiers',
          desc: 'Subscriber tiers.',
        },
        {
          icon: 'mdi-account-multiple-plus-outline',
          path: '/group_activations',
          title: 'Activations',
          desc: 'Group activations.',
        },
      ]
      this.isSLD === false && menu.push(
        {
          icon: 'mdi-chart-timeline',
          path: '/subscriber_performance',
          title: 'Performance',
          desc: 'Group performance.',
        },
      )
      this.isFc && menu.push(
        {
          icon: 'mdi-account-multiple-plus',
          path: '/fc_activations',
          title: 'FC Activations',
          desc: 'Non-group activations.',
        },
      )

      return menu
    },
  },
  mounted: function () {
    this.$rest.get('getDealerResources.php', createGetParams({})).then(function (response) {
      this.isFc = response.data.isFc
      this.isSLD = response.data.isSLD
    }.bind(this)).catch(error => {
      this.ecash = '<span style="color:red">' + error.message + '</span>'
    })
  },
}
</script>
